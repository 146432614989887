import { PedResponseCode } from "../types";

export default {
  "#001": {
    id: "MSG99614",
    description: "Ask the customer to insert or present their card or device.",
  },
  "#002": {
    id: "MSG00959",
    description:
      "The card cannot be processed by the PIN Pad. Swipe the card or press Cancel if the customer decides not to proceed.",
  },
  "#003": {
    id: "MSG99614",
    description: "Ask the customer to insert or present their card or device.",
  },
  "#004": {
    id: "MSG99614",
    description: "PIN Pad is prompting for application selection.",
  },
  "#005": {
    id: "MSG99614",
    description: "The customer is being asked to enter their PIN. Ask them to follow the instructions on the PIN Pad.",
  },
  "#006": {
    id: "MSG40079",
    description: "PIN Entry in progress (retry)",
  },
  "#007": {
    id: "MSG40079",
    description: "PIN Entry in progress (last try)",
  },
  "#008": {
    id: "MSG99613",
    description: "Prompt the customer to remove their card.",
  },
  "#009": {
    id: "MSG40079",
    description: "Unable to read card, please swipe card again.",
  },
  "#010": {
    id: "MSG40079",
    description: "Please wait, authorising",
  },
  "#011": {
    id: "MSG40079",
    description: "Please wait, processing",
  },
  "#013": {
    id: "MSG40079",
    description: "PIN blocked, please wait",
  },
  "#014": {
    id: "MSG99614",
    description: "Ask the customer to enter card number into PIN Pad.",
  },
  "#015": {
    id: "MSG00959",
    description:
      "The card cannot be processed by the PIN Pad.\n\nSwipe the card or press Cancel if the customer decides not to proceed.",
  },
  "#018": {
    id: "MSG99614",
    description: "Advise the customer to insert card.",
  },
  "#019": {
    id: "MSG99614",
    description: "Advise the customer to insert card.",
  },
  "#021": {
    id: "MSG00831",
    description: "Ask the customer to accept / decline the issuer processing fee. This is not a post office fee.",
  },
  "INSERT CARD": {
    id: "MSG99614",
    description: "Ask the customer to insert or present their card or device.",
  },
  "SIGNATURE OK": {
    id: "MSG00839",
    description: "Check the signature on the card against the receipt.\n\nSignature OK?",
  },
  "CARD ERROR SWIPE CARD": {
    id: "MSG00959",
    description:
      "The card cannot be processed by the PIN Pad.\n\nSwipe the card or press Cancel if the customer decides not to proceed.",
  },
  "CARD NOT READ SWIPE CARD AGAIN": {
    id: "MSG40079",
    description: "Unable to read card, please swipe card again.",
  },
  "REMOVE CARD": {
    id: "MSG99613",
    description: "Prompt the customer to remove their card.",
  },
} as PedResponseCode;
