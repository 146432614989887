import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response, DeviceServerEvents } from "../../types";
import { isError } from "../../helpers";

import {
  LabelPrinterResult,
  LabelPrinterClient,
  LabelPrinterActions,
  LabelPrinterPrintProps,
  LabelPrinterClientProps,
} from "./types";

export const buildLabelPrinterClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  clientProps?: LabelPrinterClientProps
): LabelPrinterClient => {
  const performAction = async (
    action: LabelPrinterActions,
    params: LabelPrinterPrintProps
  ): Promise<LabelPrinterResult> => {
    const response = (await requestHandler({
      service: SupportedServices.LabelPrinter,
      action,
      params,
    })) as Response & LabelPrinterResult;

    if (isError(response)) {
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  };

  const print = async (props: LabelPrinterPrintProps): Promise<LabelPrinterResult> => {
    return new Promise((resolve) => {
      if (clientProps?.useMock) {
        const peripheralSelectorBroadcast: BroadcastChannel = new BroadcastChannel("Peripherals");
        peripheralSelectorBroadcast.postMessage("LABEL");
        peripheralSelectorBroadcast.close();
        setTimeout(() => {
          const printBroadcast: BroadcastChannel = new BroadcastChannel("label");
          printBroadcast.postMessage("label printed");
          printBroadcast.close();
        }, 500);
        return resolve({ printed: true });
      }
      resolve(
        performAction(LabelPrinterActions.Print, {
          label: props.label,
          printer: props.printer,
        })
      );
    });
  };

  return Object.freeze({
    print,
  });
};
