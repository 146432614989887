export interface LabelPrinterResult {
  printed: boolean;
}

export interface LabelPrinterClient {
  print(props: LabelPrinterPrintProps): Promise<LabelPrinterResult>;
}

export interface LabelPrinterPrintProps {
  label: string;
  printer: LabelAvailablePrinters;
}

export interface LabelPrinterClientProps {
  useMock?: boolean;
}

export enum LabelPrinterActions {
  Print = "PRINT",
}

export enum LabelAvailablePrinters {
  RoyalMail = "label_printer_1",
  MonarchsHead = "label_printer_2",
}

/*
 * Token Mapping avaliable on confluence here:
 * https://pol-jira.atlassian.net/wiki/spaces/SPM/pages/10804855306/Postage+Sale+-+MH+Tokens+Mapping
*/ 

export interface monarchHeadLabelParams {
  labelTemplate: string | null; 
  usn: string; 
  requiredAtDelivery: string; 
  returnToSenderPostCode: string; 
  destinationCountry: string;
  upuTrackingNumber: string;
  serviceId: string;
  weightType: string;
  terminalID: string;
  fadCode: string;
  service: string;
  destAddress1?: string;
  postcode: string;
  town?: string;
  uniqueID?: string;
  weight?: string;
  price: string;
  date: string;
  vatCode: string;
  sessionID: string;
  segCode: string;
  satDel?: string;
  twoDBarcode?: string;
  barCodeReq?: string;
  altServiceId?: string;
}
