export interface ScannerStatus {
  connected: boolean;
  message: string;
}

export interface ScannerClientProps {
  useMock?: boolean;
}
export interface ScannerClient {
  connect(): Promise<ScannerStatus>;
  disconnect(): Promise<ScannerStatus>;
}

export enum ScannerActions {
  Connect = "CONNECT",
  Disconnect = "DISCONNECT",
}
