import {
  luhn16,
  parcelRefNumber,
  parcelRefNumberHyphenated,
  getRightBottomColumn,
  getAddress,
  getVATcolumn,
  getVATrow,
  generateTwoDBarcode,
  generateOneDBarcode,
  channelHex,
  usnHex,
  fadCodeHex,
  terminalIDHex,
  versionNumberHex,
} from "../helpers";
import { monarchHeadLabelParams } from "../types";

// TODO - New type
export const generateMonarchHeadLabel = (params): string => {
  const { labelTemplate } = params;

  if (labelTemplate === "postageSale2D") {
    return getMonarchsHeadWithTwoDBarcode(params);
  }

  if (labelTemplate === "null" || null) {
    return getMonarchsHead(params);
  }

  // TODO - Some error handling needed here for conditions that are if label template is equal to another variant
  return "";
};

export const getMonarchsHeadWithTwoDBarcode = (params: monarchHeadLabelParams): string => {
  const { fadCode, terminalID, satDel, upuTrackingNumber, service, barCodeReq, usn } = params;

  const getChannelHex = channelHex();
  const getVersionHex = versionNumberHex()
  const getFadCodeHex = fadCodeHex(fadCode);
  const getTerminalHex = terminalIDHex(terminalID)
  const getUSNHex = usnHex(usn);
  const prn = parcelRefNumber(getChannelHex, getVersionHex, getFadCodeHex, getTerminalHex, getUSNHex);
  const prnWithCheckDigit = luhn16(prn);
  const prnHyphenated = parcelRefNumberHyphenated(prnWithCheckDigit);
  const twoDBarcode = generateTwoDBarcode(params, prnWithCheckDigit);

  let oneDBarcode = "";
  let vat = "";

  if (barCodeReq === "true" && upuTrackingNumber) {
    oneDBarcode = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  // An excerpt of address to appear on the label.
  // If postcode (zip code) is absent, print Town.
  // Print premises (building name/number) and first 6 char of Street
  // (if present). Below that, the CountryDisplayName (MAIL-1224)

  // 40 symbols max to fit in the area

  return `^XA
^LS22^CF0,52^FO80,12^FD${service}^FS${
    satDel === "SAT" && "^FS^PR1^FO640,2^GB98,60,50,0^FS^FO654,12^CF0,52,46^FR^FDSAT^FS^CF0,25"
  }

^FX --- Data Matrix bar code ---
^FO100,95^BXN,6,200^FD${twoDBarcode}^FS

^FX --- Bottom content ---
^CF0,28
^FO84,400^FD${prnHyphenated}^FS
^FX--- Bar code ---

${oneDBarcode}

${vat}
${getAddress(params)}
${getRightBottomColumn(params)}
^XZ
`;
};

export const getMonarchsHead = (params: monarchHeadLabelParams): string => {
  const { satDel, upuTrackingNumber, service, barCodeReq, altServiceId } = params;

  let oneDBarcode = "";
  let vat = "";

  if (barCodeReq === "true" && upuTrackingNumber) {
    oneDBarcode = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  return `^XA
^LS22
^CF0,52^FO80,12^FD${service}^FS${
    satDel === "SAT" && "^FS^PR1^FO640,2^GB98,60,50,0^FS^FO654,12^CF0,52,46^FR^FDSAT^FS^CF0,25"
  }

^CF0,180^FO130,120^FD^CF0,180^FO130,120^FD${altServiceId}^FS^FS
^FX--- Bar code ---
${oneDBarcode}
${vat}
${getAddress(params)}
${getRightBottomColumn(params)}
^XZ
`;
};
