import WebSocketAsPromised from "websocket-as-promised";
import { toBlob } from "html-to-image";
import { SupportedServices, Response } from "../../types";

import {
  ReceiptPrinterResult,
  ReceiptPrinterClient,
  ReceiptPrinterProps,
  ReceiptPrinterClientProps,
  ReceiptPrinterActions,
  ReceiptPrinterModes,
} from "./types";
import { isError } from "../../helpers";

const DEFAULT_WIDTH = 300;

export const buildReceiptPrinterClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: ReceiptPrinterClientProps
): ReceiptPrinterClient => {
  const performAction = async (
    action: ReceiptPrinterActions,
    params: ReceiptPrinterProps
  ): Promise<ReceiptPrinterResult> => {
    const response = (await requestHandler({
      service: SupportedServices.ReceiptPrinter,
      action,
      params,
    })) as Response & ReceiptPrinterResult;

    if (isError(response)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  const print = async (receipt: string, mode?: ReceiptPrinterModes, width?: number): Promise<ReceiptPrinterResult> => {
    let receiptToBePrinted = receipt;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!mode || mode === ReceiptPrinterModes.Html) {
        // html-to-image expects the dom to exist in the node tree of the
        // document, it cannot frustratedly use HtmlElement's passed directly, so
        // we create a temp div which is hidden on screen and remove once processed
        document.getElementById("receipt-container")?.remove();
        const receiptContainer = document.createElement("div");
        receiptContainer.setAttribute("id", "receipt-container");

        // reason we hide it offscreen is because the library
        // interprets visible styles and if we hide, it doesn't render
        receiptContainer.style.position = "fixed";
        receiptContainer.style.bottom = "-1000px";
        receiptContainer.style.overflow = "hidden";

        const receiptElement = document.createElement("div");
        receiptElement.setAttribute("id", "generated-receipt");
        receiptElement.style.width = `${width || DEFAULT_WIDTH}px`;
        receiptElement.innerHTML = receipt;

        // add as a child of the container
        receiptContainer.appendChild(receiptElement);
        document.body.appendChild(receiptContainer);

        const blob = await toBlob(document.getElementById("generated-receipt") as HTMLElement, {
          pixelRatio: 2,
          skipAutoScale: true,
          type: "image/jpeg",
        });

        const reader = new FileReader();
        reader.readAsDataURL(blob as Blob);

        reader.onloadend = async () => {
          receiptToBePrinted = (reader.result as string).split("base64,")[1];
          receiptContainer.remove();

          if (props?.useMock) {
            const peripheralSelectorBroadcast: BroadcastChannel = new BroadcastChannel("Peripherals");
            peripheralSelectorBroadcast.postMessage("RECIEPT");
            peripheralSelectorBroadcast.close();
            setTimeout(() => {
              const printBroadcast: BroadcastChannel = new BroadcastChannel("receipt");
              printBroadcast.postMessage(receiptToBePrinted);
              printBroadcast.close();
            }, 500);
            return resolve({ printed: true });
          }

          resolve(
            performAction(ReceiptPrinterActions.Print, {
              receipt: receiptToBePrinted,
              mode: ReceiptPrinterModes.Html,
            })
          );
        };
      } else {
        resolve(
          performAction(ReceiptPrinterActions.Print, {
            receipt: receiptToBePrinted,
            mode: ReceiptPrinterModes.Text,
          })
        );
      }
    });
  };

  return Object.freeze({
    print,
  });
};
