import { monarchHeadLabelParams } from "./types";

const additionalParams = {
  upuCountryId: "JGB",
  infoTypeId: 4,
  versionId: 2,
  versionNumber: "1",
  mailType: "A",
  channel: "2",
  mailPieceType: " ",
  format: "8",
  class: "6",
};

// TODO - i think this can be done more easily
export const luhn16 = (inputHex: string): string => {
  const codeArray = inputHex.split("");

  // step 1: code point mapping (hex2decimal)
  const hexToDecDouble: (string | number)[] = codeArray.map((hex: string) => parseInt(hex, 16)); // just a copy

  // step 2: double every other value starting from the end
  for (let i = hexToDecDouble.length - 1; i >= 0; i -= 2) {
    const initialValue = hexToDecDouble[i];
    const doubled = Number(hexToDecDouble[i]) * 2;

    // step 3: convert the double decimal to base 16
    hexToDecDouble[i] = doubled.toString(16);
    if (doubled.toString(16).length > 1) {
      // convert to hex every character of a string and save them in array
      const arr = doubled.toString(16).split("");

      // step 4: reduce by splitting down any resultant values over and summing them up
      hexToDecDouble[i] = parseInt(arr[0], 10) + parseInt(arr[1], 10);
      if (isNaN(Number(hexToDecDouble[i]))) {
        hexToDecDouble[i] = initialValue;
      }
    } else if (isNaN(Number(doubled.toString(16)))) {
      hexToDecDouble[i] = initialValue; // if not a number, revert back to decimal
    } else {
      hexToDecDouble[i] = doubled.toString(16);
    }
  }
  // step 5: sum up those digits
  const sum = hexToDecDouble.reduce((a, b) => parseInt(String(a)) + parseInt(String(b)));
  const checkDigit = ((16 - (Number(sum) % 16)) % 16).toString(16);

  return (inputHex + checkDigit).toUpperCase();
};

export const channelHex = (): string => {
  return parseInt(additionalParams.channel, 10).toString(16).toUpperCase().padStart(2, "0");
};

export const versionNumberHex = (): string => {
  return parseInt(additionalParams.versionNumber).toString(16).toUpperCase();
};

export const fadCodeHex = (fadcode: string): string => {
  return parseInt(fadcode, 10).toString(16).toUpperCase().padStart(5, "0");
};

export const terminalIDHex = (terminalID: string): string => {
  return terminalID.toString().toUpperCase().padStart(2, "0");
};

// USN derived by taking terminal session number and appending transaction number,
// and then taking the rightmost 6 digits to generate a 5 character Hex code (left padded with zeros if needed)
export const usnHex = (usn: string): string => {
  const unhyphen = usn.replace(/-/g, "");
  return parseInt(String(unhyphen).slice(-6), 10).toString(16).toUpperCase().padStart(5, "0");
};

export const parcelRefNumber = (
  channelHex: string,
  versionNumberHex: string,
  fadHex: string,
  terminalIDHex: string,
  usnHex: string
): string => {
  const prn = channelHex + versionNumberHex + fadHex + terminalIDHex + usnHex;
  
  // Validate that all combined hex values are alpha numeric

  let validPRN = true;
  for (let i = 0, len = prn.length; i < len; i++) {
    const code = prn.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91)) {// upper alpha (A-Z)
      validPRN = false;
    }
  }

  // TODO - do something with error handling
  if(validPRN === false) {
    console.error("PRN not valid")
  }

  return prn
};

// This is the same as uniqueID
export const parcelRefNumberHyphenated = (prn: string): string => {
  const splitByFourSymbols = prn.match(/.{1,4}/g) || [];

  return splitByFourSymbols.join("-");
};

export const getRightBottomColumn = (params: monarchHeadLabelParams): string => {
  return `^FX --- Date, Seg code ---

^FO1080,300^FB130,3,0,R,0^FD${params.date} \\& ${params.weight ? parseInt(params.weight, 10) / 1000 : "0"} \\& ${
    parseInt(params.price, 10) / 100
  }^FS

^FX --- Circle, Seg code ---
^FO1150,380^GC80,3,B^FS^CF0,60^FO1174,396^FD${params.segCode}^FS
`;
};

export const getVATcolumn = (params: monarchHeadLabelParams): string => {
  const { terminalID, vatCode, sessionID, fadCode } = params;

  return `
^FX --- y6 ---
^CF0,25^FO980,328^FDVAT ${vatCode}^FS

^FX --- Bottom Right content ---
^CFA,25,8^FO980,360^FD${terminalID}-${sessionID}^FS^FO980,390^FDBR ${fadCode}^FS
`;
};

export const getVATrow = (params: monarchHeadLabelParams): string => {
  const { terminalID, vatCode, sessionID, fadCode } = params;
  return `
^CF0,25^FO550,400^FD BR ${fadCode}  ${terminalID}-${sessionID} VAT ${vatCode}^FS
`;
};

export const getAddress = (params: monarchHeadLabelParams): string => {
  const { postcode, town, destAddress1, barCodeReq, destinationCountry } = params;
  let address = postcode ? postcode : town;
  address = address + ", " + destAddress1;

  // 40 symbols max to fit in the area
  const numberOfSymbolsToTrim = (destinationCountry + address).length > 30 ? address.length - 30 : 0;
  address = numberOfSymbolsToTrim > 0 ? address.slice(0, -numberOfSymbolsToTrim) : address;

  // if we don't have a barcode address is printed to the right of 2D
  let addressPosition = 200;
  if (barCodeReq === "true") {
    addressPosition = 360;
  }
  const countryPosition = addressPosition + 40;
  return `
^FO520,${addressPosition}^FD ${address}^FS
^CF0,25^FO712,${countryPosition}^FD${destinationCountry}^FS`;
};

export const generateOneDBarcode = (upuTrackingNumber: string): string => {
  return `^BY3,2,188^FO424,95^BC^FD${upuTrackingNumber}^FS`;
};

export const generateTwoDBarcode = (journeyParams: monarchHeadLabelParams, prnWithCheckDigit: string): string => {
  const { upuCountryId, infoTypeId, versionId ,format, class:RMClass , mailType, mailPieceType} = additionalParams
  const { fadCode, serviceId:product, date, upuTrackingNumber, weight, weightType, price, destAddress1, postcode, destinationCountry, returnToSenderPostCode, requiredAtDelivery } = journeyParams
  const padString = " ";
  const formatDate = date.replace(/\//g, "");

  const barcodeString =
    upuCountryId.padEnd(4, padString) +
    infoTypeId +
    versionId +
    format.padEnd(2, padString) +
    RMClass +
    mailType +
    prnWithCheckDigit +
    fadCode +
    (weight ? weight.padStart(7, "0") : "0000000") +
    weightType +
    price.padStart(5, "0") +
    formatDate +
    product +
    mailPieceType.padEnd(2, padString) +
    upuTrackingNumber.padEnd(13, padString) +
    destAddress1?.padEnd(35, padString) +
    postcode.padEnd(9, padString) +
    destinationCountry +
    returnToSenderPostCode.padEnd(7, padString) +
    (requiredAtDelivery || padString) +
    padString +
    padString;

  return barcodeString;
};
