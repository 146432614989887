import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response } from "../../types";

import { ScannerStatus, ScannerClient, ScannerActions, ScannerClientProps } from "./types";
import { isError } from "../../helpers";
import { performAction as performSimulatorAction } from "./mock/index";

const FAILED_TO_CONNECT = "Failed to connect";

export const buildScannerClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: ScannerClientProps
): ScannerClient => {
  const performAction = async (action: ScannerActions, params: Record<string, unknown>): Promise<ScannerStatus> => {
    if (props?.useMock) {
      return Promise.resolve(performSimulatorAction(action));
    }

    const response = (await requestHandler({
      service: SupportedServices.Scanner,
      action,
      params,
    })) as Response & ScannerStatus;

    if (isError(response)) {
      return Promise.reject({
        message: `${FAILED_TO_CONNECT}: ${response.message}`,
        service: response.service,
        result: response.result,
      });
    }

    return Promise.resolve(response);
  };

  return Object.freeze({
    connect: () => performAction(ScannerActions.Connect, {}),
    disconnect: () => performAction(ScannerActions.Disconnect, {}),
  });
};
